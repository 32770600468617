import clsx from "clsx";
import { PropsWithChildren } from "react";

const Tooltip: React.FC<
  PropsWithChildren<{
    title: string;
    top?: true;
    bottom?: true;
    left?: true;
    right?: true;
    z?: number;
    noWrap?: boolean;
  }>
> = ({ children, title, top, bottom, left, right, z, noWrap }) => {
  return (
    <div className="has-tooltip relative w-auto flex items-center justify-center cursor-pointer">
      {children}
      <div
        className={clsx(
          "tooltip p-2 rounded-lg dark:bg-grey-600 bg-neutral-100 text-xs text-transparent",
          top && "bottom-full",
          bottom && "top-full",
          right && "left-1/2",
          left && "right-1/2",
          z && `!z-[${z}]`
        )}
      >
        <p
          className={clsx(
            "break-normal dark:text-white text-gray-900",
            noWrap && "whitespace-nowrap"
          )}
        >
          {title}
        </p>
        <svg
          className="absolute dark:text-grey-600 text-neutral-100 h-2 left-0 ml-3 top-full"
          x="0px"
          y="0px"
          viewBox="0 0 255 255"
        >
          <polygon className="fill-current" />
        </svg>
      </div>
    </div>
  );
};

export default Tooltip;
