import clsx from "clsx";
import { format } from "date-fns";
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { useCache } from "../cache-provider";
import useMakeSFRequest from "../request/salesforce/make-sf-call";
import { ApiTask } from "../types/task";
import { createDateLabelForTask } from "../utils/create-date-label-for-task";
import { createListingDescription } from "../utils/create-listing-description";
import { listingStatusColor } from "../utils/listing-status-color";
import { taskStatusColor } from "../utils/task-status-color";
import { Attachment } from "./attachment";
import { useAuth } from "./auth/firebase-context";
import { LoadingAttachments } from "./loading-attachments";
import { createTaskBedConfig } from "./number-of-beds";
import { pluralize } from "./pluralize";
import { PostTask } from "./post-task";
import {
  ConfirmActionDialog,
  PrimaryActions,
  SecondaryActions,
  TimedActions,
} from "./task-actions";
import Tooltip from "./tooltip";
import {
  convertBillable,
  convertCurrency,
} from "../utils/maintenance-task-utils";

type TaskDetailsProps = {
  id: string;
  task: ApiTask;
  taskPermission: {
    is_my_task: boolean;
    is_manageable_by_user: boolean;
    is_claimable_by_user: boolean;
  };
  refreshTask(taskData: any): Promise<void>;
  drawerProps?: { onClose(): void };
  pageProps?: { isPage: true };
};

export const TaskDetails: React.FC<TaskDetailsProps> = ({
  id,
  task,
  taskPermission,
  drawerProps,
  pageProps,
  refreshTask,
}) => {
  const { user: curUser } = useAuth();
  const { allQueues } = useCache();
  const onClose = () => {
    setAttachments([]);
    if (drawerProps) {
      drawerProps.onClose();
    }
  };
  const { additional_housekeepers, related } = task;
  const bedConfig = useMemo(
    () =>
      createTaskBedConfig(
        task.id,
        related?.king_bed_count || 0,
        related?.queen_bed_count || 0,
        related?.full_bed_count || 0,
        related?.single_bed_count || 0
      ),
    [task]
  );
  const beddingLabel = useMemo(
    () =>
      bedConfig
        .filter(({ count }) => count > 0)
        .map((config) => `${config.count}${config.icon}`)
        .join(" | "),
    [bedConfig]
  );
  const [action, setAction] = useState<ActionT>(null);
  const [followUpTask, setFollowUpTask] = useState(false);
  const [openEditTask, setOpenEditTask] = useState(false);
  const [selectedSecondaryAction, setSelectedSecondaryAction] = useState({
    title: "Defer",
    action: "deferTask",
    description: "Defer task to another date.",
  });
  const request = useMakeSFRequest();
  // messages
  const [getMessages, setGetMessages] = useState<FeedItemT[]>([]);
  const [postMessageLoading, setPostMessageLoading] = useState<boolean>(false);
  const [postMessageText, setPostMessageText] = useState<string>("");
  const [attachments, setAttachments] = useState<(File & { id?: string })[]>(
    []
  );
  const [numberAttachments, setNumberAttachments] = useState(0);
  const [attachmentsLoading, setAttachmentsLoading] = useState(true);
  const [attachmentsMetaLoading, setAttachmentsMetaLoading] = useState(true);
  const [getMessagesLoading, setGetMessagesLoading] = useState<boolean>(false);
  //TODO: [Ray] handle these errors
  const [getMessagesError, setGetMessagesError] = useState<string | null>(null);
  const [postMessageError, setPostMessageError] = useState<string | null>(null);
  const [attachmentsError, setAttachmentsError] = useState<string | null>(null);
  // const isNextReservationOwner = related !== undefined && related.next_check_in_type === 'Owner';
  const hasAdditionalHousekeepers =
    additional_housekeepers && additional_housekeepers.length > 0;
  const hasHouseman = task.related?.hasOwnProperty("houseman") || false; // we always show houseman on task drawer
  const hasInspector =
    task.sub_type === "Glass & Lanai"
      ? task.related?.hasOwnProperty("inspector")
      : false;
  const hasEngineer =
    task.sub_type === "Departure Clean"
      ? task.related?.hasOwnProperty("engineer")
      : false;
  const isCase = task.related?.hasOwnProperty("case_number") || false;
  const hasPreviousHousekeeper =
    task.type === "Inspection" &&
    task.sub_type === "Final" &&
    task.related?.previous_housekeeper;
  const showBedsInfo =
    task.related && (task.type === "Clean" || task.type === "Drop");

  const getAttachmentsRequest = async () => {
    if (!task) {
      return;
    }
    setNumberAttachments(0);
    setAttachmentsError(null);
    setAttachmentsMetaLoading(true);
    const response = await request({
      endpointPath: `/api/attachment/${task.id}`,
      method: "GET",
    });
    if (response.error) {
      setAttachmentsError(
        "There was a problem getting attachments for this task."
      );
      setAttachmentsMetaLoading(false);
      return;
    }
    setNumberAttachments((response as any).attachments.length);
    setAttachmentsMetaLoading(false);
    setAttachmentsLoading(true);
    const attachments = await Promise.all(
      (response as any).attachments.map(async (attach: any) => {
        const attachmentHeaders = new Headers();
        attachmentHeaders.set("Accept", "*/*");
        const attachmentResponse = await request({
          endpointPath: `/api/attachment/${attach.id}`,
          method: "GET",
          contentType: attach.content_type,
          headers: attachmentHeaders,
        });
        if (attachmentResponse.error && (attachmentResponse as Response).blob) {
          setAttachmentsLoading(false);
          return null;
        }
        const blob = await (attachmentResponse as Response).blob();
        const fileData = new File([blob], attach.name, {
          type: attach.content_type,
        });
        return Object.assign(fileData, { id: attach.id });
      })
    );
    setAttachments(attachments);
    setAttachmentsLoading(false);
  };
  const getMessagesRequest = async () => {
    if (!task) {
      return;
    }
    setGetMessagesLoading(true);
    setGetMessagesError(null);
    const response = await request({
      endpointPath: `/api/feed/${task.id}`,
      method: "GET",
    });
    if (response.error) {
      setGetMessagesError(
        "There was a probleming getting messages related to this task."
      );
      setGetMessagesLoading(false);
      return;
    }
    setGetMessages((response as any).feed_items);
  };
  const postMessage = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!task || !postMessageText) {
      return;
    }
    setPostMessageError(null);
    setPostMessageLoading(true);
    try {
      const response = await request({
        endpointPath: `/api/feed/${task.id}`,
        method: "POST",
        body: JSON.stringify({
          body: postMessageText,
          is_rich_text: false,
          type: "TextPost",
          parent_id: task.id,
        }),
      });
      if (response.error) {
        setPostMessageError("There was a problem posting your message.");
        setPostMessageLoading(false);
        return;
      }
      setGetMessages((prev) => [
        ...prev,
        {
          ...(response as any).feed_item,
          owner_id: curUser?.sf_user_id,
          owner_name: curUser?.name,
          modstamp: new Date().toJSON(),
        },
      ]);
    } catch (e) {
      setPostMessageError("There was a problem posting your message.");
      setPostMessageLoading(false);
      return;
    }
    setPostMessageText("");
    setPostMessageLoading(false);
  };
  useEffect(() => {
    Promise.all([getMessagesRequest(), getAttachmentsRequest()]);
  }, [task]);
  const onDeleteAttachment = useCallback(
    async (fileName: string, attachment_id?: string) => {
      if (attachment_id) {
        await request({
          endpointPath: `/api/attachment/${attachment_id}`,
          method: "DELETE",
        });
      }
      setAttachments((prev) => {
        return prev.filter((file) => {
          return file.name !== fileName;
        });
      });
    },
    []
  );
  //NOTE: We can't add attachments to a task that's assigned to a group due to conflicting 500 issues.
  //      Attachment owner must match task owner, but attachment owner cannot be a Group.
  const queueIds =
    allQueues.success && allQueues.data
      ? allQueues.data.map((queue: any) => queue.id)
      : [];
  const uploadAllowed = !queueIds.includes(task.assignee_id); // && [task.assignee_id, task.reporter_id].includes(curUser.sf_user_id);
  const deleteAllowed =
    curUser &&
    [task.assignee_id, task.reporter_id].includes(curUser.sf_user_id);
  const shouldLookAtNextCheckInType =
    task.sub_type === "Departure Clean" ||
    (task.sub_type === "Drop & Strip" && task.related.sub_type !== "Tidy");
  const guestTypeLabel = shouldLookAtNextCheckInType
    ? task.related.next_check_in_type
    : task.related.guest_type;
  return (
    <>
      <div className="h-full w-dvw sm:w-full overflow-y-auto bg-white dark:!bg-gray-900 p-4 pt-6 animate-fade-in">
        <br className="sm:hidden" />
        <div id="task-title-status-section" className="flex flex-col">
          <div className="flex flex-row items-start">
            <h2
              className={clsx(
                "grow flex text-base font-semibold text-primary-main dark:text-secondary-main",
                taskStatusColor(task.status)
              )}
            >
              {task.status}
            </h2>
            <div className="flex flex-row justify-between gap-2">
              {task.related && task.related.listing_status && (
                <Tooltip title="Related status." bottom>
                  <span
                    className={clsx(
                      "w-fit text-sm ml-1 inline-flex flex-shrink-0 items-center rounded-full bg-primary-main px-1.5 py-0.5 font-medium text-white ring-1 ring-inset ring-primary-light whitespace-nowrap",
                      listingStatusColor(task.related.listing_status)
                    )}
                  >
                    {task.related.listing_status}
                  </span>
                </Tooltip>
              )}
              {task.related && task.related.for_sale && (
                <Tooltip title="Unit is for sale.">
                  <div className="w-fit flex px-2 border border-error-dark rounded-lg whitespace-nowrap items-center ml-1 text-error-dark ">
                    <span className="icon-[map--real-estate-agency] text-inherit mr-1" />
                    <span className="text-sm">For Sale</span>
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
          <p className="text-2xl lg:text-3xl font-bold tracking-tight text-gray-900 dark:text-white">
            {createDateLabelForTask(task)}
            {task.is_high_priority && (
              <span className="truncate">
                ・
                <span className="text-bold text-error-dark">
                  {task.priority}
                </span>
              </span>
            )}
          </p>
          <p className="text-lg lg:text-2xl break-words leading-8 text-gray-600 dark:text-gray-300">
            {task.title}
            {(!pageProps || !pageProps.isPage) && (
              <Link
                onClick={onClose}
                to={`/tasks/${task.id}`}
                className="ml-1 text-sm underline text-primary-main hover:text-secondary-main dark:text-white dark:hover:text-gray-100"
              >
                full task
              </Link>
            )}
          </p>
        </div>
        <section
          id="task-details-section"
          className="grid grid-cols-6 gap-4 mt-2 border-b border-gray-300 pb-4"
        >
          {task.type && (
            <div className="flex flex-col col-span-3">
              <label className="text-sm text-gray-600 dark:text-gray-300">
                Type
              </label>
              <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                {task.type}
              </p>
            </div>
          )}
          {task.sub_type && (
            <div className="flex flex-col col-span-3">
              <label className="text-sm text-gray-600 dark:text-gray-300">
                Subtype
              </label>
              <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                {task.sub_type}
              </p>
            </div>
          )}
          {/* For cases, we show the owner instead of the assignee. */}
          {isCase ? (
            <>
              {task.related && task.related.owner_name && (
                <div className="flex flex-col col-span-3">
                  <label className="text-sm text-gray-600 dark:text-gray-300">
                    Owner
                  </label>
                  <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                    {task.related.owner_name}
                  </p>
                </div>
              )}
            </>
          ) : (
            <>
              {task.assignee && task.assignee.name && (
                <div className="flex flex-col col-span-3">
                  <label className="text-sm text-gray-600 dark:text-gray-300">
                    Assignee
                  </label>
                  {task.assignee?.user_type === "Standard" && (
                    <Link
                      onClick={() => {
                        onClose();
                      }}
                      to={`/users/${task.assignee_id}`}
                      className="underline text-lg leading-6 text-gray-900 dark:text-white text-primary-main hover:text-secondary-main"
                    >
                      {task.assignee.name}
                    </Link>
                  )}
                  {task.assignee?.user_type !== "Standard" && (
                    <h6 className="text-lg leading-6 text-gray-700 dark:text-white">
                      {task.assignee.name}
                    </h6>
                  )}
                </div>
              )}
            </>
          )}
          {task.reporter && task.reporter.name && (
            <div className="flex flex-col col-span-3">
              <label className="text-sm text-gray-600 dark:text-gray-300">
                Reporter
              </label>
              {task.reporter?.user_type === "Standard" && (
                <Link
                  onClick={() => {
                    onClose();
                  }}
                  to={`/users/${task.reporter_id}`}
                  className="underline text-lg leading-6 text-gray-900 dark:text-white text-primary-main hover:text-secondary-main"
                >
                  {task.reporter.name}
                </Link>
              )}
              {task.reporter?.user_type !== "Standard" && (
                <h6 className="text-lg leading-6 text-gray-700 dark:text-white">
                  {task.reporter.name}
                </h6>
              )}
            </div>
          )}
          {task.type === "Maintenance" && (
            <>
              {task.billable_hours !== undefined && task.billable_hours > 0 && (
                <div className="flex flex-col col-span-3">
                  <label className="text-sm text-gray-600 dark:text-gray-300">
                    Billable Hours
                  </label>
                  <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                    {convertBillable(String(task.billable_hours))}
                  </p>
                </div>
              )}
              {task.material_costs !== undefined && task.material_costs > 0 && (
                <div className="flex flex-col col-span-3">
                  <label className="text-sm text-gray-600 dark:text-gray-300">
                    Material Costs
                  </label>
                  <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                    {convertCurrency(String(task.material_costs))}
                  </p>
                </div>
              )}
            </>
          )}
          {(task.type === "Drop" || task.type === "Pickup") &&
            task.inventory_id && (
              <div className="flex flex-col col-span-3">
                <label className="text-sm text-gray-600 dark:text-gray-300">
                  Inventory Id
                </label>
                <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                  {task.inventory_id}
                </p>
              </div>
            )}
          {task.start_time && (
            <div className="flex flex-col col-span-3">
              <label className="text-sm text-gray-600 dark:text-gray-300">
                Started On
              </label>
              <time
                dateTime={task.start_time}
                className="text-lg leading-6 text-gray-900 dark:text-white"
              >
                {format(new Date(task.start_time), "MM/d/yy, h:mm a")}
              </time>
            </div>
          )}
          {task.completed_datetime && (
            <div className="flex flex-col col-span-3">
              <label className="text-sm text-gray-600 dark:text-gray-300">
                Completed On
              </label>
              <time
                dateTime={task.completed_datetime}
                className="text-lg leading-6 text-gray-900 dark:text-white"
              >
                {format(new Date(task.completed_datetime), "MM/d/yy, h:mm a")}
              </time>
            </div>
          )}
          {hasAdditionalHousekeepers && (
            <div className="flex flex-col col-span-3">
              <label className="text-sm text-gray-600 dark:text-gray-300">
                Additional Housekeepers
              </label>
              {task.additional_housekeepers.map((name: string, i: number) => (
                <p
                  className="sm:text-lg leading-6 text-gray-900 dark:text-white"
                  key={i}
                >
                  {name}
                </p>
              ))}
            </div>
          )}
          {hasHouseman && (
            <div className="flex flex-col col-span-3">
              <label className="text-sm text-gray-600 dark:text-gray-300">
                Houseman
              </label>
              <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                {task.related.houseman}
              </p>
            </div>
          )}
          {hasInspector && (
            <div className="flex flex-col col-span-3">
              <label className="text-sm text-gray-600 dark:text-gray-300">
                Inspector
              </label>
              <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                {task.related.inspector}
              </p>
            </div>
          )}
          {hasEngineer && (
            <div className="flex flex-col col-span-3">
              <label className="text-sm text-gray-600 dark:text-gray-300">
                Engineer
              </label>
              <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                {task.related.engineer}
              </p>
            </div>
          )}
          {hasPreviousHousekeeper && (
            <div className="flex flex-col col-span-3">
              <label className="text-sm text-gray-600 dark:text-gray-300 truncate">
                Previous Housekeeper
              </label>
              <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                {task.related.previous_housekeeper}
              </p>
            </div>
          )}
          {task.type === "Shuttle Service" && (
            <>
              {task.pickup_location && (
                <div className="flex flex-col col-span-6">
                  <label className="text-sm text-gray-600 dark:text-gray-300">
                    Pick Up Location
                  </label>
                  <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                    {task.pickup_location}
                  </p>
                </div>
              )}
              {task.dropoff_location && (
                <div className="flex flex-col col-span-6">
                  <label className="text-sm text-gray-600 dark:text-gray-300">
                    Drop Off Location
                  </label>
                  <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                    {task.dropoff_location}
                  </p>
                </div>
              )}
              <div className="flex flex-col col-span-3">
                <label className="text-sm text-gray-600 dark:text-gray-300">
                  Adults
                </label>
                <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                  {task.number_of_adults || 0}
                </p>
              </div>
              <div className="flex flex-col col-span-3">
                <label className="text-sm text-gray-600 dark:text-gray-300">
                  Children
                </label>
                <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                  {task.number_of_children || 0}
                </p>
              </div>
              <div className="flex flex-col col-span-3">
                <label className="text-sm text-gray-600 dark:text-gray-300">
                  Waiver
                </label>
                <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                  {task.waiver_verified ? "Verified" : "Unverified"}
                </p>
              </div>
            </>
          )}
          {task.description && (
            <div className="flex flex-col col-span-6">
              <label className="text-sm text-gray-600 dark:text-gray-300">
                Description
              </label>
              <p className="sm:text-lg break-words leading-6 text-gray-900 dark:text-white line-clamp-[40]">
                {task.description}
              </p>
            </div>
          )}
        </section>
        {["Listing__c", "Listing_Order_Item__c"].includes(task.related_type) &&
          task.related && (
            <section
              id="task-related-to-listing-section"
              className="grid grid-cols-2 gap-4 mt-4 border-b border-gray-300 pb-4"
            >
              <label className="text-lg text-gray-600 dark:text-gray-300 col-span-2">
                Related to Listing
              </label>
              <div className="flex flex-col col-span-2">
                <label className="text-sm text-gray-600 dark:text-gray-300">
                  Listing
                </label>
                <Link
                  onClick={onClose}
                  to={`/listings/${task.related_id}`}
                  className="underline text-lg leading-6 text-gray-900 dark:text-white text-primary-main hover:text-secondary-main"
                >
                  {(task.related as ApiListing).name_detailed}
                </Link>
                <p className="sm:text-lg leading-6 text-gray-600 dark:text-gray-300">
                  {createListingDescription(task.related)}
                </p>
              </div>
              {task.related.check_out && (
                <div className="flex flex-col col-span-1">
                  <label className="text-sm text-gray-600 dark:text-gray-300">
                    Checkout
                  </label>
                  <time
                    dateTime={task.related.check_out}
                    className="text-lg leading-6 text-gray-900 dark:text-white"
                  >
                    {format(
                      new Date(task.related.check_out),
                      "MM/d/yy, h:mm a"
                    )}
                  </time>
                </div>
              )}
              {/* TODO: Per Dalton 9/26/24 - This 'Manager' label should not be needed. */}
              {task.related.owner_name && (
                <div className="flex flex-col col-span-1">
                  <label className="text-sm text-gray-600 dark:text-gray-300">
                    Manager
                  </label>
                  <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                    {task.related.owner_name}
                  </p>
                </div>
              )}
              {task.related.next_check_in && (
                <div className="flex flex-col col-span-1">
                  <label className="text-sm text-gray-600 dark:text-gray-300">
                    Next Check In
                  </label>
                  <time
                    dateTime={task.related.next_check_in}
                    className="text-lg leading-6 text-gray-900 dark:text-white"
                  >
                    {format(
                      new Date(task.related.next_check_in),
                      "MM/d/yy, h:mm a"
                    )}
                  </time>
                </div>
              )}
              {task.related.lessor_name && (
                <div className="flex flex-col">
                  <label className="text-sm text-gray-600 dark:text-gray-300">
                    Owner
                  </label>
                  <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                    {task.related.lessor_name}
                  </p>
                </div>
              )}
              {showBedsInfo && beddingLabel && (
                <div className="flex flex-col col-span-1">
                  <label className="text-sm text-gray-600 dark:text-gray-300">
                    Bedding
                  </label>
                  <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                    {beddingLabel}
                  </p>
                </div>
              )}
            </section>
          )}
        {["Case"].includes(task.related_type) && task.related && (
          <section
            id="task-related-to-listing-section"
            className="grid grid-cols-2 gap-4 mt-4 border-b border-gray-300 pb-4"
          >
            <label className="text-lg text-gray-600 dark:text-gray-300 col-span-2">
              Related to Case
            </label>
            {task.related.subject && (
              <div className="flex flex-col col-span-2">
                <label className="text-sm text-gray-600 dark:text-gray-300">
                  Subject
                </label>
                <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                  {task.related.subject}
                </p>
              </div>
            )}
            {task.related.case_number && (
              <div className="flex flex-col col-span-1">
                <label className="text-sm text-gray-600 dark:text-gray-300">
                  Case Number
                </label>
                <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                  {task.related.case_number || "-"}
                  {task?.related.priority === "High" && (
                    <span
                      className={
                        "icon-[solar--double-alt-arrow-up-line-duotone] text-error-dark"
                      }
                    />
                  )}
                </p>
              </div>
            )}
            {showBedsInfo && beddingLabel && (
              <div className="flex flex-col col-span-1">
                <label className="text-sm text-gray-600 dark:text-gray-300">
                  Bedding
                </label>
                <p className="sm:text-lg leading-6 text-gray-900 dark:text-white whitespace-nowrap">
                  {beddingLabel}
                </p>
              </div>
            )}
            {task.related.status && (
              <div className="flex flex-col col-span-1">
                <label className="text-sm text-gray-600 dark:text-gray-300">
                  Status
                </label>
                <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                  {task.related.status}
                </p>
              </div>
            )}
            {task.related.sub_type && (
              <div className="flex flex-col col-span-1">
                <label className="text-sm text-gray-600 dark:text-gray-300">
                  Sub Type
                </label>
                <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                  {task.related.sub_type}
                </p>
              </div>
            )}
            {task.related.check_out && (
              <div className="flex flex-col col-span-1">
                <label className="text-sm text-gray-600 dark:text-gray-300">
                  Checkout
                </label>
                <time
                  dateTime={task.related.check_out}
                  className="text-lg leading-6 text-gray-900 dark:text-white"
                >
                  {format(new Date(task.related.check_out), "MM/d/yy, h:mm a")}
                </time>
              </div>
            )}
            {task.related.next_check_in && (
              <div className="flex flex-col col-span-1">
                <label className="text-sm text-gray-600 dark:text-gray-300">
                  Next Check In
                </label>
                <time
                  dateTime={task.related.next_check_in}
                  className="text-lg leading-6 text-gray-900 dark:text-white"
                >
                  {format(
                    new Date(task.related.next_check_in),
                    "MM/d/yy, h:mm a"
                  )}
                </time>
              </div>
            )}
          </section>
        )}
        {["Reservation__c", "Reservation_Order_Item__c"].includes(
          task.related_type
        ) &&
          task.related && (
            <section
              id="task-related-to-reservation"
              className="grid grid-cols-6 gap-4 mt-4 border-b border-gray-300 pb-4"
            >
              <label className="text-lg text-gray-600 dark:text-gray-300 col-span-6">
                Related to Reservation
              </label>
              {task.related.check_in_requests && (
                <div className="flex flex-col col-span-3">
                  <label className="text-sm text-gray-600 dark:text-gray-300">{`${pluralize(
                    task.related.check_in_requests.split(";").length,
                    "Request"
                  )} (${
                    task.related.check_in_requests.split(";").length
                  })`}</label>
                  <ul className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                    {task.related.check_in_requests
                      .split(";")
                      .map((request: string, index: number) => (
                        <li className="truncate" key={`${request}-${index}`}>
                          {request}
                        </li>
                      ))}
                  </ul>
                </div>
              )}
              {task.related.check_in_requests_detail && (
                <div
                  className={clsx(
                    "flex flex-col",
                    task.related.check_in_requests ? "col-span-3" : "col-span-6"
                  )}
                >
                  <label className="text-sm text-gray-600 dark:text-gray-300">
                    Request Description
                  </label>
                  <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                    {task.related.check_in_requests_detail}
                  </p>
                </div>
              )}
              <div className="flex flex-col col-span-3">
                <label className="text-sm text-gray-600 dark:text-gray-300">
                  Reservation
                </label>
                <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                  {task.related.name}
                </p>
              </div>
              {showBedsInfo && beddingLabel && (
                <div className="flex flex-col col-span-3">
                  <label className="text-sm text-gray-600 dark:text-gray-300">
                    Bedding
                  </label>
                  <p className="sm:text-lg leading-6 text-gray-900 dark:text-white whitespace-nowrap">
                    {beddingLabel}
                  </p>
                </div>
              )}
              {task.type === "Inspection" &&
                task.sub_type === "Final" &&
                task.related.previous_housekeeper && (
                  <div className="flex flex-col col-span-3">
                    <label className="text-sm text-gray-600 dark:text-gray-300">
                      Previous Housekeeper
                    </label>
                    <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                      {task.related.previous_housekeeper}
                    </p>
                  </div>
                )}
              {task.related.listing_id && (
                <div className="flex flex-col col-span-6">
                  <label className="text-sm text-gray-600 dark:text-gray-300">
                    Listing
                  </label>
                  <Link
                    onClick={onClose}
                    to={`/listings/${task.related.listing_id}`}
                    className="underline text-lg leading-6 text-gray-900 dark:text-white text-primary-main hover:text-secondary-main"
                  >
                    {(task.related as ApiListing).name_detailed}
                  </Link>
                  <p className="sm:text-lg leading-6 text-gray-600 dark:text-gray-300">
                    {createListingDescription(task.related)}
                  </p>
                </div>
              )}
              {task.related.check_in && (
                <div className="flex flex-col col-span-3">
                  <label className="text-sm text-gray-600 dark:text-gray-300">
                    Check In
                  </label>
                  <time
                    dateTime={task.related.check_in}
                    className="sm:text-lg leading-6 text-gray-900 dark:text-white"
                  >
                    {format(new Date(task.related.check_in), "M/d/yy, h:mm a")}
                  </time>
                </div>
              )}
              {task.related.check_out && (
                <div className="flex flex-col col-span-3">
                  <label className="text-sm text-gray-600 dark:text-gray-300">
                    Check Out
                  </label>
                  <time
                    dateTime={task.related.check_out}
                    className="sm:text-lg leading-6 text-gray-900 dark:text-white"
                  >
                    {format(new Date(task.related.check_out), "M/d/yy, h:mm a")}
                  </time>
                </div>
              )}
              {task.related.guest_name && (
                <div className="flex flex-col col-span-3">
                  <label className="text-sm text-gray-600 dark:text-gray-300">
                    Guest
                  </label>
                  <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                    {task.related.guest_name}
                  </p>
                </div>
              )}
              {guestTypeLabel && (
                <div className="flex flex-col col-span-3">
                  <label className="text-sm text-gray-600 dark:text-gray-300">
                    Guest Type
                  </label>
                  <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                    {guestTypeLabel}
                  </p>
                </div>
              )}
              {typeof task.related?.check_in_gap === "number" &&
                task.related.check_in_gap > 0 && (
                  <div className="flex flex-col col-span-3">
                    <label className="text-sm text-gray-600 dark:text-gray-300">
                      Check In Gap
                    </label>
                    <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                      {task.related.check_in_gap}
                    </p>
                  </div>
                )}
              {typeof task.related?.check_out_gap === "number" &&
                task.related.check_out_gap > 0 && (
                  <div className="flex flex-col col-span-3">
                    <label className="text-sm text-gray-600 dark:text-gray-300">
                      Check Out Gap
                    </label>
                    <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                      {task.related.check_out_gap}
                    </p>
                  </div>
                )}
              {task.related.previous_check_out && (
                <div className="flex flex-col col-span-3">
                  <label className="text-sm text-gray-600 dark:text-gray-300">
                    Previous Check Out
                  </label>
                  <time
                    dateTime={task.related.previous_check_out}
                    className="sm:text-lg leading-6 text-gray-900 dark:text-white"
                  >
                    {format(
                      new Date(task.related.previous_check_out),
                      "M/d/yy, h:mm a"
                    )}
                  </time>
                </div>
              )}
              {task.related.next_check_in && (
                <div className="flex flex-col col-span-3">
                  <label className="text-sm text-gray-600 dark:text-gray-300">
                    Next Check In
                  </label>
                  <time
                    dateTime={task.related.next_check_in}
                    className="sm:text-lg leading-6 text-gray-900 dark:text-white"
                  >
                    {format(
                      new Date(task.related.next_check_in),
                      "M/d/yy, h:mm a"
                    )}
                  </time>
                </div>
              )}
            </section>
          )}
        {task.contact && (
          <section
            id="task-related-to-listing-section"
            className="grid grid-cols-2 gap-4 mt-4 border-b border-gray-300 pb-4"
          >
            <label className="text-lg text-gray-600 dark:text-gray-300 col-span-2">
              Contact
            </label>
            <div className="flex flex-col">
              <label className="text-sm text-gray-600 dark:text-gray-300">
                Contact{" "}
                {task.contact.is_owner && (
                  <span className="icon-[material-symbols--star-outline]" />
                )}
              </label>
              <p className="sm:text-lg leading-6 text-gray-900 dark:text-white">
                {task.contact.name || "-"}
              </p>
            </div>
            <div className="flex flex-col">
              <label className="text-sm text-gray-600 dark:text-gray-300">
                Phone
              </label>
              <a
                href={`tel:${task.contact.phone}`}
                className="sm:text-lg leading-6 text-primary-main hover:text-secondary-main dark:text-white"
              >
                {task.contact.phone || "-"}
              </a>
            </div>
            <div className="flex flex-col">
              <label className="text-sm text-gray-600 dark:text-gray-300">
                Mobile
              </label>
              <a
                href={`tel:${task.contact.mobile_phone}`}
                className="sm:text-lg leading-6 text-primary-main hover:text-secondary-main dark:text-white"
              >
                {task.contact.mobile_phone || "-"}
              </a>
            </div>
            <div className="flex flex-col">
              <label className="text-sm text-gray-600 dark:text-gray-300">
                Email
              </label>
              <a
                href={`mailTo:${task.contact.email}`}
                className="sm:text-lg leading-6 text-primary-main hover:text-secondary-main dark:text-white"
              >
                {task.contact.email || "-"}
              </a>
            </div>
          </section>
        )}
        {curUser && task && (
          <section
            id="task-related-to-reservation"
            className="mt-4 border-b border-gray-300 pb-4"
          >
            <label className="text-lg text-gray-600 dark:text-gray-300 col-span-6">
              Messages
            </label>
            <div className="flex items-start space-x-4 mt-4">
              <div className="flex-shrink-0">
                <div className="bg-primary-main w-8 h-8 rounded-full capitalize text-white flex items-center justify-center text-center">
                  {/* TODO: Null check here (failsafe - it only happens if a legacy FS record gets through somehow) */}
                  {curUser.first_name.charAt(0)}
                  {curUser.last_name.charAt(0)}
                </div>
              </div>
              <div className="min-w-0 flex-1 w-full">
                <form
                  onSubmit={postMessage}
                  className="relative"
                  id={`${task.id}-message-form-${id}`}
                >
                  <div className="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-primary-main">
                    <label htmlFor="comment" className="sr-only">
                      Add your comment
                    </label>
                    <textarea
                      rows={3}
                      name="comment"
                      id={`comment-${id}`}
                      className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 dark:text-white placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="Add your comment..."
                      value={postMessageText}
                      onChange={(e) => {
                        const val = e.currentTarget.value;
                        setPostMessageText(val);
                      }}
                      onInput={(e) => {
                        const val = e.currentTarget.value;
                        setPostMessageText(val);
                      }}
                    />

                    {/* Spacer element to match the height of the toolbar */}
                    <div className="py-2" aria-hidden="true">
                      {/* Matches height of button in toolbar (1px border + 36px content height) */}
                      <div className="py-px">
                        <div className="h-9" />
                      </div>
                    </div>
                  </div>

                  <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                    <div className="flex items-center space-x-5">
                      <div className="flex items-center">
                        {uploadAllowed && (
                          <div className="w-full flex justify-end items-center text-sm leading-6 text-gray-600 dark:text-gray-300">
                            <label
                              htmlFor="file-upload"
                              className="relative flex cursor-pointer rounded-md bg-white dark:bg-gray-900 font-semibold text-primary-main focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-main focus-within:ring-offset-2 hover:text-secondary-main"
                            >
                              <span
                                className="h-5 w-5 icon-[mdi--paperclip] text-gray-500 dark:hover:text-secondary-main"
                                aria-hidden="true"
                              />
                              <input
                                disabled={attachmentsLoading}
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                className="sr-only"
                                onInput={async (e) => {
                                  if (e.currentTarget.files) {
                                    setAttachmentsLoading(true);
                                    setNumberAttachments(
                                      e.currentTarget.files.length
                                    );
                                    const filesPayload: File[] = [];
                                    let fileIndex = 0;
                                    while (
                                      fileIndex < e.currentTarget.files.length
                                    ) {
                                      filesPayload.push(
                                        e.currentTarget.files[fileIndex]
                                      );
                                      fileIndex += 1;
                                    }
                                    await Promise.all(
                                      filesPayload.map((file) =>
                                        request({
                                          endpointPath: `/api/attachment/${task.id}?filename=${file.name}`,
                                          method: "POST",
                                          body: file,
                                          contentType: file.type,
                                        })
                                      )
                                    ).catch(() => {
                                      setAttachmentsError(
                                        "There was a problem posting attachment."
                                      );
                                      setAttachmentsLoading(false);
                                      return;
                                    });
                                    setAttachments((prev) => [
                                      ...prev,
                                      ...filesPayload,
                                    ]);
                                    setAttachmentsLoading(false);
                                    return;
                                  }
                                  setAttachments([]);
                                }}
                              />
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex-shrink-0">
                      <button
                        disabled={postMessageLoading}
                        form={`${task.id}-message-form-${id}`}
                        type="submit"
                        className="inline-flex items-center rounded-md bg-primary-main dark:bg-secondary-main dark:hover:bg-primary-main px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-main focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-main"
                      >
                        {postMessageLoading ? (
                          <span className="icon-[uiw--loading] animate-spin" />
                        ) : (
                          <span>Post</span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
                {postMessageError && (
                  <span className="text-xs text-error-dark">
                    There was a problem posting message.
                  </span>
                )}
              </div>
            </div>
            {attachments.length > 0 && (
              <div
                className={clsx(
                  pageProps?.isPage
                    ? "grid-cols-3 sm:grid-cols-4 lg:grid-cols-6"
                    : "grid-cols-3",
                  "mt-2 grid grid-flow-row gap-2"
                )}
              >
                {attachments.map((attach, attachIndex) => (
                  <Attachment
                    attachment_id={attach.id}
                    loading={attachmentsLoading}
                    onDelete={deleteAllowed ? onDeleteAttachment : undefined}
                    file={attach}
                    key={`${attach.name}-${attachIndex}-preview`}
                  />
                ))}
                {attachmentsLoading && (
                  <LoadingAttachments num={numberAttachments} />
                )}
              </div>
            )}
            {(attachmentsMetaLoading || attachmentsLoading) &&
              attachments.length === 0 && (
                <div className="mt-2">
                  <LoadingAttachments num={numberAttachments} />
                </div>
              )}
            {attachmentsError && (
              <span className="text-xs text-error-dark">
                There was a problem getting attachments.
              </span>
            )}
            {getMessages.length > 0 && (
              <div className="mt-4 flex flex-col gap-4">
                {getMessages
                  .sort(
                    (a, b) =>
                      new Date(a.modstamp).getTime() -
                      new Date(b.modstamp).getTime()
                  )
                  .map((message, index) => (
                    <div
                      key={`${message.id}-${index}`}
                      className="relative flex flex-col group"
                    >
                      <div
                        className={clsx(
                          "w-full flex gap-5",
                          curUser.sf_user_id !== message.owner_id
                            ? "flex-row"
                            : "flex-row-reverse"
                        )}
                      >
                        <div
                          className={clsx(
                            curUser.sf_user_id === message.owner_id
                              ? "bg-primary-main"
                              : "bg-success-main",
                            "flex-none w-6 h-6 text-xs rounded-full capitalize text-white flex items-center justify-center text-center"
                          )}
                        >
                          {message.owner_name
                            ? message.owner_name
                                .split(" ")
                                .map((part) => part.charAt(0))
                                .join("")
                            : "??"}
                        </div>
                        <div
                          className={clsx(
                            curUser.sf_user_id === message.owner_id
                              ? "bg-secondary-main text-white"
                              : "bg-success-main text-white",
                            "px-2 py-1.5 rounded-lg max-w-[250px]"
                          )}
                        >
                          {message.is_rich_text ? (
                            <div
                              className="text-sm"
                              dangerouslySetInnerHTML={{ __html: message.body }}
                            />
                          ) : (
                            <p className="text-sm">{message.body}</p>
                          )}
                        </div>
                      </div>
                      {message.modstamp && (
                        <p
                          className={clsx(
                            "w-full flex gap-5",
                            curUser.sf_user_id !== message.owner_id
                              ? "text-left pl-12"
                              : "text-right pr-12",
                            "-mb-4 text-xs text-gray-400 hidden group-hover:block group-focus:block animate-fade-in"
                          )}
                        >
                          {format(new Date(message.modstamp), "M/d/yy, h:mm a")}
                        </p>
                      )}
                    </div>
                  ))}
              </div>
            )}
            {getMessagesError && (
              <span className="text-xs text-error-dark">
                There was a problem getting feed items.
              </span>
            )}
          </section>
        )}
        <footer className="relative my-4 flex justify-between items-center">
          <div>
            <TimedActions
              task={task}
              onClick={setAction}
              permission={taskPermission}
            />
          </div>
          <div className="flex items-center gap-1 sm:gap-2">
            <SecondaryActions
              task={task}
              onClick={setAction}
              permission={taskPermission}
              selected={selectedSecondaryAction}
              setSelected={setSelectedSecondaryAction}
            />
            <PrimaryActions
              task={task}
              onClick={setAction}
              permission={taskPermission}
              onEditTask={() => {
                setOpenEditTask(true);
              }}
            />
          </div>
        </footer>
        {(task.created_date || task.last_modified_date) && (
          <footer className="mt-4">
            {task.created_date && (
              <p className="text-gray-500 text-sm truncate">
                Created on{" "}
                <time dateTime={task.created_date}>
                  {format(new Date(task.created_date), "PPP @ H:mm a")}
                </time>
              </p>
            )}
            {task.last_modified_date &&
              task.created_date !== task.last_modified_date && (
                <p className="text-gray-500 text-sm truncate">
                  Update on{" "}
                  <time dateTime={task.last_modified_date}>
                    {format(new Date(task.last_modified_date), "PPP @ H:mm a")}
                  </time>
                </p>
              )}
          </footer>
        )}
      </div>
      {task && action && action !== "editTask" && (
        <ConfirmActionDialog
          setOpenEditTask={setOpenEditTask}
          setFollowUpTask={setFollowUpTask}
          onClose={() => {
            setSelectedSecondaryAction({
              title: "Defer",
              action: "deferTask",
              description: "Defer task to another date.",
            });
            setAction(null);
          }}
          action={action}
          fullPageMode={pageProps?.isPage || false}
          refreshTask={refreshTask}
          task={task}
        />
      )}
      {task && action && (
        <PostTask
          followUpTask={followUpTask}
          hideButton
          loading={false}
          onRefresh={async (taskData) => {
            if (drawerProps) {
            }
            if (pageProps) {
            }
            await refreshTask(taskData);
          }}
          autoOpen={openEditTask || action === "editTask"}
          autoOnClosed={() => {
            setOpenEditTask(false);
            setFollowUpTask(false);
            setAction(null);
          }}
          task={task}
        />
      )}
    </>
  );
};
