import {
  User as FirebaseUser
} from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import AuthorizationResponse from '../../../classes/auth';
import User from '../../../classes/user';
import { CheckLocalDev, auth, firestore } from '../../config/firebase/app';
import { UserSettings } from '../../../classes/user-settings';

export const DB_USERS_KEY = 'Users'; // capitalized in the Ohana portal (case-sensitive)

type AuthContextModel = {
  authCounter: number,
  initialized: boolean,
  user: User | null,
  clearSFCredentials: () => void,
  logout: () => void,
  updateSFCredentials: (params: AuthorizationResponse) => void
};

const AuthContext = createContext<AuthContextModel>({
  authCounter: 0,
  initialized: false,
  user: null,
  clearSFCredentials: () => { },
  logout: () => { },
  updateSFCredentials: () => { }
});

type AuthProviderProps = {
  children: React.ReactNode;
};

const verboseLogging = false && CheckLocalDev();

export function AuthProvider({ children }: AuthProviderProps) {
  const [authCounter, setAuthCounter] = useState<number>(0);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);

  const stateChangeUseCallback = useCallback(() => {
    return auth.onAuthStateChanged(
      async (firebaseUser) => {
        verboseLogging && console.log('onAuthStateChanged; firebaseUser', firebaseUser);

        if (!firebaseUser) {
          if (user) {
            verboseLogging && console.log('No FB user; clearing');
            setUser(null);
          }

          setInitialized(true);
          return;
        }

        await getFirestoreUserRecord(firebaseUser)
          .then(async (data) => {
            verboseLogging && console.log('received firestore data for ' + firebaseUser.email, data);
            if (!data) {
              throw new Error('Bad data received from firestore!');
            }
            let userSettings: UserSettings | null = null;
            if (data.user_settings) {
              userSettings = new UserSettings(data.user_settings.dark_mode, data.user_settings.task_order);
            }
            const newUser = new User({ ...data as any }, userSettings);
            setUser(newUser);

            //TODO: Ensure the theme is initialized from user settings; dark mode mainly. Theme changed with Tailwind.

            if (!data.sf_access_token) {
              verboseLogging && console.log('Firestore user data missing Salesforce access token.');
            }

            if (!data.sf_refresh_token) {
              verboseLogging && console.log('Firestore user data missing Salesforce refresh token.');
            }
          })
          .catch((e) => {
            console.warn('Failed to fetch firestore user data on load', e);
          })
          .finally(() => {
            setInitialized(true);
          });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unsubscribeCallback = stateChangeUseCallback();
    return unsubscribeCallback;
  }, [stateChangeUseCallback]);

  const logout = useCallback(async () => {
    await auth.signOut();
    setUser(null);
  }, []);

  const clearSFCredentials = useCallback(async () => {
    if (!user) {
      verboseLogging && console.warn('clearing SF without a user');
      return;
    }

    user.clearSFCredentials();

    //NOTE: This clear() is only needed to show the Salesforce guard, which means local model, only.
    //      We don't need to update Firestore, yet. If that changes, use this:
    //await setFirestoreUserRecord(user);

    // this stepper triggers a render, which is important to show the Salesforce Oauth prompt
    setAuthCounter(authCounter + 1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const updateSFCredentials = useCallback(async (params: AuthorizationResponse) => {
    if (!user) {
      verboseLogging && console.log('updating SF without a user', params);
      return;
    }
    if (!params) {
      verboseLogging && console.log('updating SF without params', params);
      return;
    }

    // cross-reference this SF user w/ the session user, and block as needed
    if (user.sf_user_id !== params.getUser_Id()) {
      throw new Error('You must log into Salesforce as ' + user.name);
    }

    user.setSFCredentials(params);

    if (await setFirestoreUserRecord(user)) {
      return;
    }

    verboseLogging && console.warn('SF credentials update failed');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const memoizedValue = useMemo(
    () => ({
      authCounter: authCounter,
      initialized: initialized,
      user: user,
      clearSFCredentials: clearSFCredentials,
      logout: logout,
      updateSFCredentials: updateSFCredentials
    }),
    [
      authCounter,
      initialized,
      user,
      clearSFCredentials,
      logout,
      updateSFCredentials
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

// local helpers

async function getFirestoreUserRecord(firebaseUser: FirebaseUser) {
  // console.log("getting FS user record for email: " + firebaseUser.email);
  try {
    if (!firestore) {
      throw new Error('Problem in getFirestoreUserRecord');
    }
    if (!firebaseUser.uid) { // this is now username
      throw new Error('getFirestoreUserRecord missing uid');
    }
    const userRef = doc(firestore, DB_USERS_KEY, firebaseUser.uid);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      return userDoc.data();
    }

    throw Error("Failed to get user record from firestore");
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

async function setFirestoreUserRecord(user: User) {
  // console.log("setting FS user record for sf_user_id: " + user.sf_user_id);
  try {
    if (!firestore) {
      throw new Error('Problem in setFirestoreUserRecord');
    }
    if (!user.sf_user_id) {
      throw new Error('setFirestoreUserRecord missing sf_user_id');
    }
    const userRef = doc(firestore, DB_USERS_KEY, user.sf_user_id);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      return false;
    }

    const userJSON = JSON.parse(JSON.stringify(user));

    const payload = {
      ...userDoc.data(),
      ...userJSON
    };

    await setDoc(userRef, payload);
    return true;
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}
